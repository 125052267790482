


















































































































































































































































































































































.video-modal {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  .big_box {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 880px;
    height: 495px;
  }
  .video-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 880px;
    height: 495px;
    cursor: pointer;
  }
  .close-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 440px;
    margin-top: -246px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
  .controls {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

/* 暂停 或者 播放 */
.custom-video_play {
  margin-left: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
}

/* hover 播放按钮动画 */
.custom-video_play:hover {
  box-shadow: 0 0 10px #5a4180;
  transition: all 0.4s;
}
/* 控制栏 */
.custom-video_control {
  position: absolute;
  width: 100%;
  height: 50px;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
}
/* 控制栏进度条 */
.custom-video_control-bg {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
/* 控制栏进度条 —— 总长度 */
.custom-video_control-bg-outside {
  width: 100%;
  height: 5px;
  border-radius: 2.5px;
  background-color: #aaa;
  position: relative;
  cursor: pointer;
}
/* 控制栏进度条 —— 播放长度 */
.custom-video_control-bg-inside {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 100%;
  border-radius: 2.5px;
  left: 0;
  top: 0;
  background-color: #fff;
  // transition: all 0.2s;
}
/* 控制栏进度条 —— 播放点 */
.custom-video_control-bg-inside-point {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: -2.5px;
  left: -1%;
  transition: all 0.2s;
}
/* 控制栏 —— 声音、时间、全屏缩放 */
.custom-video_control-voice,
.custom-video_control-time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
}
.custom-video_control-voice:hover > .custom-video_control-voice-bg {
  display: block;
}
.custom-video_control-voice-play {
  margin-top: 5px;
  z-index: 10;
}
.custom-video_control-voice-bg {
  display: none;
  position: absolute;
  width: 30px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.55);
  left: 0;
  bottom: 0px;
  border-radius: 15px;
}
.custom-video_control-voice-bg-outside {
  width: 5px;
  height: 70px;
  border-radius: 2.5px;
  background-color: #aaa;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 10%, 0);
  cursor: pointer;
}
.custom-video_control-voice-bg-inside {
  display: inline-block;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 2.5px;
  background-color: #fff;
  height: 0;
}
.custom-video_control-voice-bg-point {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: -2.5px;
  bottom: -1px;
}
.custom-video_control-time {
  margin-right: 10px;
  font-size: 12px;
}

/* hover 显示 */
.custom-video_container:hover > .custom-video_play-pause {
  display: inline-block;
}
.custom-video_control-voice {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
/* 控制栏隐藏动画 */
// .fade-enter-active {
//   transition: all 0.3s ease;
// }
// .fade-leave-active {
//   transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
// }
.fade-enter,
.fade-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
.visiblity {
  visibility: hidden;
}
