

























.mobile-box {
  position: relative;
  width: 20.625vw;
  height: 35.989583333333336vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252259933744862.png);
  background-size: cover;
  background-repeat: no-repeat;
  .mobile-video {
    position: absolute;
    z-index: 2;
    left: 2.65625vw;
    top: 3.28125vw;
    width: 13.072916666666666vw;
    height: 28.333333333333332vw;
    border-radius: 1.0416666666666667vw;
    object-fit: cover;
    box-shadow: 21px 10px 64px 0px rgba(0, 0, 0, 0.33);
  }
}
